import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {  
  const data = useStaticQuery(graphql`
    query Images {
      allFile(sort: {fields: name, order: ASC}, filter: {relativeDirectory: {eq: "thumbnails"}}) {
        nodes {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  //console.log(data)

  return (
    <>
  <Layout backgroundColor="rgb(251,251,253)">
    <SEO title="Codebars - Code experiments for an upcoming project." />

    <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
      <div class="col-span-1">
        <Link to="/page-1/"><Img fluid={data.allFile.nodes[0].childImageSharp.fluid} alt="Sketch 1" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-2/"><Img fluid={data.allFile.nodes[1].childImageSharp.fluid} alt="Sketch 2" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-3/"><Img fluid={data.allFile.nodes[2].childImageSharp.fluid} alt="Sketch 2" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-4/"><Img fluid={data.allFile.nodes[3].childImageSharp.fluid} alt="Sketch 1" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-5/"><Img fluid={data.allFile.nodes[4].childImageSharp.fluid} alt="Sketch 2" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-6/"><Img fluid={data.allFile.nodes[5].childImageSharp.fluid} alt="Sketch 1" /></Link>
      </div> 
      <div class="col-span-1">
        <Link to="/page-7/"><Img fluid={data.allFile.nodes[6].childImageSharp.fluid} alt="Sketch 8" /></Link>
      </div>
      <div class="col-span-1">
        <Link to="/page-8/"><Img fluid={data.allFile.nodes[7].childImageSharp.fluid} alt="Sketch 9" /></Link>
      </div> 
      <div class="col-span-1">
        <Link to="/page-9/"><Img fluid={data.allFile.nodes[8].childImageSharp.fluid} alt="Sketch 11" /></Link>
      </div>   
    </div>
    
    <div class="flex  my-10">
      <div class="w-full text-center">
        <p>Code experiments for an upcoming project.</p>
      </div>
    </div>

  </Layout>
  </>
  )
}

export default IndexPage